// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyAHYD3nBJA8NUwzC6FgFb7pWqJZRIJ1Tl8",
  authDomain: "fidi-44780.firebaseapp.com",
  databaseURL: "https://fidi-44780.firebaseio.com",
  projectId: "fidi-44780",
  storageBucket: "fidi-44780.appspot.com",
  messagingSenderId: "986831313692",
  appId: "1:986831313692:web:def3f3522a7a80ecd7b8d9",
  measurementId: "G-ZVHSCLMED9"
}
// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/fidi-44780.appspot.com/o/'